@use '../../../../styles/colors';
@use '../../../../styles/mixin';
@use '../../../../styles/common';

.Container {
  background: url(../../../../assets/feed_bkg.svg) no-repeat center center;
  background-size: cover;
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-center;
  padding: 40px 10px;
  @media screen and (min-width: 768px) {
    padding: 40px;
  }
}

.UlFeed {
  width: 100%;
  @extend .display-flex;
  @extend .flex-direction-column;

  @media screen and (min-width: 768px) {
    @include mixin.flex-direction-row;
  }

  li {
    width: 100%;

    &:first-of-type {
      padding: 0 0 10px 0;
    }
    &:last-of-type {
      padding: 10px 0 0 0;
    }
    @media screen and (min-width: 768px) {
      &:first-of-type {
        padding: 0 10px 0 0;
      }
      &:last-of-type {
        padding: 0 0 0 10px;
      }
    }

    p {
      display: block;
      margin-bottom: 20px;
    }
    img {
      width: 33.33333%;
      padding: 5px;
      @extend .bsbb;
    }
  }
}

.FollowUs {
  @extend .common-link;
}
