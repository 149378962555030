// SASS Var Branding Color [HEX]
// Order: Alphabetic

// A
$adobe: #ff0000; // same
$alphabet: #ed1c24;
$amazon: #ff9700;
$android: #a4c639;
$angularjs: #b52e31;
$aol: #ff0b00;
$apple: #737373;
$archlinux: #1793d1;

// B
$behance: #1769ff;
$bing: #ffb900;
$blogger: #f57d00;

// C
$coke: #ed1c16;

// D
$digitalocean: #008bcf;
$disqus: #2e9fff;
$dribbble: #ea4c89;
$dropbox: #007ee5; 
$drupal: #0077c0;

// E
$ebay: #083790;
$envato: #82b541;
$evernote: #7ac142;

// F
$facebook: #3b5998;
$flickr: #ff0084;
$flipboard: #e12828;
$forrst: #5b9a68;
$foursquare: #0072b1;

// G
$github: #333333;
$godaddy: #7db701;
$google_1: #4285f4;
$google_2: #34a853;
$google_3: #fbbc05;
$google_4: #ea4335;
$googleplus: #dd4b39;
$gravatar: #1e8cbe;

// H
$hsbc: #db0011;
$html5: #e34f26;

// I
$ibm: #006699;
$imgur: #85bf25;
$instagram: #125688;
$intel: #0f7dc2;

// J
$jquery: #0769ad;

// L
$lastfm: #d51007;
$linkedin: #007bb5;

// M
$mailchimp: #449a88;
$mcdonalds: #bf0c0c;
$mercadolibre: #ffe600;
$microsoftoffice: #ea3e23;

// N
$netflix: #e50914;
$npm: #cb3837;
$nvidia: #76b900;

// O
$opera: #cc0f16;
$oracle: #ff0000; // same

// P
$paypal: #009cde;
$php: #8892be;
$pinterest: #cb2027;
$prezi: #318bff;

// Q
$quora: #a82400;

// R
$reddit: #cee3f8;
$ruby: #cc342d;

// S
$skype: #00acf4;
$snapchat: #fffc00;
$spotify: #2ebd59;
$stackoverflow: #fe7a15;
$starbucks: #00704a;
$strumbleupon: #eb4924;
$subway: #00543d;

// T
$telegram: #0088cc;
$tesla: #cc0000;
$tmobile: #e20074;
$tumblr: #32506d;
$twitter: #00aced;
$typekit: #98ce1e;

// U
$ubuntu: #dd4814;

// V
$vimeo: #162221;
$vine: #00bf8f;
$vk: #45668e;
$vodafone: #e60000;

// W
$whatsapp: #43d854;
$windows: #00bcf2;
$wordpress: #21759b;

// X
$xbox: #52b043;

// Y
$yahoo: #410093;
$yandex: #ffcc00;
$youtube: #bb0000;

// Z
$zendesk: #78a300;