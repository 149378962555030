@use '../../../../styles/colors';
@use '../../../../styles/mixin';
@use '../../../../styles/social';

.Whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;

  background: social.$whatsapp;
  @extend .display-flex;
  @extend .flex-center;
  color: colors.$white;
  height: 40px;
  width: 40px;
  @include mixin.border-radius(50%);
  text-decoration: none;
  z-index: colors.$zi-scroll-top;
  font-size: 20px;
}
