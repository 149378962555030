@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Container {
  border: 10px solid colors.$pink-300;
}

.Privacy {
  margin-top: 10px;
}

.Submit {
  background: colors.$pink-300;
  border: 0;
  margin-top: 10px;
  padding: 10px 20px;
  &:disabled {
    @include mixin.opacity(0.5);
  }
}

.Message {
  color: colors.$pink-300;
  margin-top: 10px;
}
