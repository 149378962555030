@use 'colors';
@use 'mixin';

.common-link {
  background: colors.$pink-300;
  display: inline-block;
  padding: 15px 20px;
  font-family: colors.$emphasis-font-family;
  text-decoration: none;
  @include mixin.border-radius(5px);
  border: 2px solid colors.$pink-300;
  font-weight: 900;
  &:hover {
    background: transparent;
  }
  i {
    margin-right: 10px;
  }
}

.UlTextImg {
  @extend .display-flex;
  @extend .flex-direction-column;
  @media screen and (min-width: 768px) {
    @include mixin.flex-row;
  }

  &:not(:first-of-type) {
    margin-top: 30px;
  }
  li {
    width: 100%;
    &:first-of-type {
      margin-bottom: 20px;
    }
    &:last-of-type {
      margin-top: 20px;
    }
    @media screen and (min-width: 768px) {
      &:first-of-type {
        padding-right: 20px;
        margin-bottom: 0;
      }
      &:last-of-type {
        padding-left: 20px;
        margin-top: 0;
      }
    }
    img {
      width: 100%;
    }
    b {
      font-weight: 600;
    }
  }
}
