@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.NextEvent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: colors.$footer;
  z-index: colors.$zi-next-event;
  text-align: center;
  @extend .display-flex;
  @extend .flex-center;
}

.Link {
  font-size: 13px;
  font-family: colors.$emphasis-font-family;
  text-decoration: none;
  i {
    margin-right: 10px;
  }
  &:hover {
    color: colors.$pink-300;
  }
}
