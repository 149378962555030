@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Footer {
  width: 100%;
  text-align: center;
  font-size: 13px;
  padding: 40px 10px;
  @extend .bsbb;
  background: colors.$footer;
  @extend .display-flex;
  @extend .flex-direction-column;
  @extend .flex-center;
  color: colors.$white;
  white-space: nowrap;
  font-weight: 100;
}

.UlContacts {
  @extend .display-flex;
  @extend .flex-direction-row;
  margin-bottom: 40px;
  li {
    margin: 0 20px;
    a {
      color: colors.$white;
      text-decoration: none;
      &:hover {
        color: colors.$pink-300;
      }
    }
  }
}

.Link {
  text-decoration: none;
  font-size: 12px;
  color: colors.$footer-copy-text;
  text-transform: uppercase;
  font-weight: 100;
  &:hover {
    color: colors.$pink-100;
  }
}

.Copy {
  font-size: 12px;
  font-weight: 100;
  background: colors.$footer-copy;
  height: 40px;
  padding: 10px;
  @extend .bsbb;
  @extend .display-flex;
  @extend .flex-justify-between;
  color: colors.$footer-copy-text;
  text-transform: uppercase;
}
