@use '../../../styles/colors';
@use '../../../styles/mixin';

.LoaderBkg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba($pink-300, 0.9);
  background: colors.$pink-300;
  z-index: colors.$zi-page-loader;
  @extend .display-flex;
  @extend .flex-center;
}

.Logo {
  width: 200px;
  animation: pulse 2.5s infinite linear both;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
