@font-face {
  font-family: 'Veneer';
  src: url('./fonts/Veneer-Three.woff') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rift';
  src: url('./fonts/Rift Regular.woff') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
