@use 'colors';
@use 'mixin';
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: colors.$bkg;
}

body,
div,
input,
select,
textarea,
a,
button,
pre {
  font-size: 18px;
  font-family: colors.$base-font-family;
  vertical-align: top;
  font-weight: 400;
  color: colors.$white;
  border: 0;
  outline: none;
  // cursor: none;
}
textarea {
  resize: none;
}

a,
button {
  cursor: pointer;
  @extend .trstn;
  &:hover {
    @extend .trstn;
  }
}

::selection {
  background-color: colors.$pink-300;
  color: colors.$white;
}

::-moz-selection {
  background-color: colors.$pink-300;
  color: colors.$white;
}

strong {
  font-weight: 800;
  color: colors.$pink-300;
}

h1 {
  font-size: 60px;
  text-transform: uppercase;
  margin-bottom: 20px;
  width: 100%;
  font-weight: 600;
  font-family: colors.$title-font-family;
  color: colors.$pink-300;
  white-space: pre-wrap;
  word-break: break-word;
  letter-spacing: 2px;
  -webkit-font-smoothing: antialiased;
  @extend .display-flex;
  font-weight: 400;

  &:not(:first-of-type),
  &.mt {
    margin-top: 20px;
  }
}

h2 {
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 20px;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  letter-spacing: 2px;
  font-family: colors.$title-font-family;
  color: colors.$white;
  &:not(:first-of-type),
  &.mt {
    margin-top: 20px;
  }
}

h3 {
  font-size: 20px;
  text-transform: uppercase;
  margin: 20px 0 0 0;
  letter-spacing: 2px;
  color: colors.$pink-300;
  font-weight: 600;
}

input,
select,
textarea {
  padding: 10px;
  background: colors.$white;
  @include mixin.border-radius(5px);
}
textarea {
  min-height: 200px;
}

::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* View transitions */
// ::view-transition-old(root) {
//   animation: fade-and-scale-out 0.5s ease-in-out 1 forwards;
// }
// ::view-transition-new(root) {
//   animation: fade-and-scale-in 1s ease-in-out 1 forwards;
// }

// @keyframes fade-and-scale-in {
//   from {
//     opacity: 0;
//     // transform: scale(0);
//   }
//   to {
//     opacity: 1;
//     // transform: scale(1);
//   }
// }
// @keyframes fade-and-scale-out {
//   from {
//     opacity: 1;
//     // transform: scale(1);
//   }
//   to {
//     opacity: 0;
//     // transform: scale(0);
//   }
// }
// /* Second animation */
// @keyframes slide-in {
//   from {
//     opacity: 0;
//     transform: translateX(100%);
//   }
//   to {
//     opacity: 1;
//     transform: translateX(0);
//   }
// }
// @keyframes slide-out {
//   from {
//     opacity: 1;
//     transform: translateX(0);
//   }
//   to {
//     opacity: 0;
//     transform: translateX(-100%);
//   }
// }
