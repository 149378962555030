@use '../../../styles/colors';
@use '../../../styles/mixin';

.Container {
  width: 100%;
  @extend .display-flex;
  @extend .flex-center;
  @extend .flex-direction-column;
  padding: 40px 0;
}

.Content {
  @extend .bsbb;
  @extend .responsive-width;
  padding: 0 10px;
}
