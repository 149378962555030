@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Nav {
  position: fixed;
  top: 30px;
  left: 0;
  width: 100%;
  @extend .bsbb;
  padding: 10px;
  background: colors.$footer;
  z-index: colors.$zi-nav;
  border-bottom: 1px solid colors.$grey-800;
  border-top: 1px solid colors.$grey-800;
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-justify-between;
  button {
    height: 40px;
    width: 40px;
    @extend .display-flex;
    @extend .flex-center;
  }
}

.LogoLink {
  height: 40px;
  margin-right: 10px;
  img {
    height: 100%;
  }
}

.Menu {
  z-index: colors.$zi-menu;
  top: 0;
  position: fixed;
  left: -250px;
  width: 250px;
  height: 100%;
  background: colors.$footer;
  @extend .trstn;
}

.MenuOpen {
  @extend .trstn;
  left: 0;
}

.UlMenu {
  margin-top: 110px;
  margin-left: 20px;
  li {
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }
}

.NavLink {
  color: colors.$white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 20px;
  @extend .trstn;
  font-family: colors.$emphasis-font-family;
  &:hover {
    @extend .trstn;
    color: colors.$pink-300;
  }
}

.NavActive {
  @extend .NavLink;
  color: colors.$pink-300;
  font-weight: 600;
}

.MenuBackground {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: colors.$zi-menu - 1;
}

.MenuBackgroundOpen {
  left: 0;
}
